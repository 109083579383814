
img {
  xxpointer-events: none;
}

.unselectable3 {
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.camera-landscape {
  height: 60vh !important;
  width: auto !important;
}

.camera-portrait {
  height: auto !important;
  width: 100vw !important;
}

@media (orientation: portrait) {
  .vid {

  }

  .media-on-card {
    height: 60%;
  }

  .book-on-card {
    width: auto;
    height: auto;
  }

  .card-itself {
    width: 100%;
    margin-bottom: 0.3em;
    /*height: 40vh !important;*/
  }

  .camFeedback {
    max-height: 60vh;
  }
}

@media (orientation: landscape) {
  .camFeedback {
    max-height: 90vh;
  }

  .media-on-card {
    height: 60vh;
  }

  .book-on-card {
    width: auto;
    height: auto;
  }

  .card-itself {
    width: 49%;
    margin-right: 0.3em;
    margin-bottom: 0.3em;
    /*height: 80vh !important;*/
  }
}

.pageIndicatorStart {
  font-size: 4em;
  /*font-weight: bolder;*/
  /*position: absolute;*/
  position: fixed;
  top: 10%;
  /*height: 50px;*/
  /*width: 100%;*/
  z-index: 100;
}
