











































































































































































































































































































































































.camera-medium {
  height: 100%;
  max-height: 50vh;
}

video {
  width: 100%;
  height: 100%;
}

canvas {
  width: 100%;
  height: 100%;
}

video2 {
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
