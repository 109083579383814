




















































































































































































































































































































































































































.snapshot-medium {
  height: 100% !important;
  max-height: 60vh;
}

.strip {
  height: 1em;
}
